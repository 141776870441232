import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  viewBox: "0 0 40 27"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", { d: "M0 4a4 4 0 0 1 4-4h32a4 4 0 0 1 4 4v18.875a4 4 0 0 1-4 4H4a4 4 0 0 1-4-4z" }, null, -1),
    _createElementVNode("path", { d: "M23.59 6.805h-7.38V20.07h7.381V6.805Z" }, null, -1),
    _createElementVNode("path", {
      fill: "#fff",
      d: "M16.677 13.44A8.42 8.42 0 0 1 19.9 6.805a8.436 8.436 0 1 0 0 13.267 8.42 8.42 0 0 1-3.223-6.632m16.873-.001A8.436 8.436 0 0 1 19.9 20.07a8.436 8.436 0 0 0 0-13.267 8.437 8.437 0 0 1 13.65 6.632z"
    }, null, -1),
    _createElementVNode("path", {
      fill: "#fff",
      "fill-opacity": ".2",
      d: "M4 1h32v-2H4zm35 3v18.875h2V4zm-3 21.875H4v2h32zm-35-3V4h-2v18.875zm3 3a3 3 0 0 1-3-3h-2a5 5 0 0 0 5 5zm35-3a3 3 0 0 1-3 3v2a5 5 0 0 0 5-5zM36 1a3 3 0 0 1 3 3h2a5 5 0 0 0-5-5zM4-1a5 5 0 0 0-5 5h2a3 3 0 0 1 3-3z"
    }, null, -1)
  ])))
}
export default { render: render }